if (document.URL.match(/(dev|com)\/dashboard/))
{

    window.dashboard = function (group)
    {

        return {

            group: group,
            active_stat: '',

            init()
            {
                if (this.group === 'mortgage')
                {
                    this.get_tasks();
                } else if (this.group !== 'agent')
                {
                    this.get_license_stats();
                }

                if (this.group === 'agent')
                {
                    this.get_seller_concessions();
                    this.get_pre_listings();
                    this.get_coming_soon();
                }
            },
            /* agents */
            get_seller_concessions()
            {
                let scope = this;
                axios.get('/agents/seller_concessions/get_seller_concessions_dashboard', {
                    params: { full_details: 'yes' }
                })
                    .then(function (response)
                    {
                        scope.$refs.seller_concessions_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            get_pre_listings()
            {
                let scope = this;
                axios.get('/agents/pre_listings/get_pre_listings_dashboard')
                    .then(function (response)
                    {
                        scope.$refs.pre_listings_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            get_coming_soon()
            {
                let scope = this;
                axios.get('/agents/coming_soon/get_coming_soon_dashboard')
                    .then(function (response)
                    {
                        scope.$refs.coming_soon_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            /* end agents */

            get_tasks()
            {
                let scope = this;
                axios.get('/dashboard/get_tasks')
                    .then(function (response)
                    {
                        scope.$refs.tasks_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                    });
            },

            get_license_stats()
            {
                let scope = this;
                let page = 'company_licenses';
                if (document.URL.match(/(dev|com)\/dashboard/))
                {
                    page = 'dashboard';
                }
                axios.get('/licenses/get_license_stats', { params: { page: page } })
                    .then(function (response)
                    {
                        if (response.data)
                        {
                            scope.$refs.license_stats_div.innerHTML = response.data;
                            // scope.show_license_stats = true;
                        }
                    })
                    .catch(function (error)
                    {

                    });
            },

        }

    }

}
