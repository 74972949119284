if (document.URL.match(/agents\/pre_listings/))
{

    window.pre_listings = function (add)
    {

        return {
            show_add_address: false,
            show_edit_pre_listing: false,

            init()
            {
                let scope = this;
                scope.get_pre_listings();
                setTimeout(function ()
                {
                    scope.address_search();
                    scope.add_photos();
                }, 1000);

                if (add)
                {
                    scope.show_add_address = true;
                }
            },

            get_pre_listings()
            {
                let scope = this;
                axios.get('/agents/pre_listings/get_pre_listings')
                    .then(function (response)
                    {
                        scope.$refs.pre_listings_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },
            address_search()
            {

                let scope = this;

                let forms = ['add_address_form', 'edit_pre_listing_form'];

                forms.forEach(function (form)
                {
                    let id = '_1';
                    if (form == 'edit_pre_listing_form')
                    {
                        id = '';
                    }
                    form = document.getElementById(form);
                    let street_address = form.querySelector('input[name="street_address' + id + '"]');


                    // google address search
                    let places = new google.maps.places.Autocomplete(street_address);

                    google.maps.event.addListener(places, 'place_changed', function ()
                    {
                        let address_details = places.getPlace();
                        let street_number,
                            street_name,
                            city,
                            state,
                            zip;

                        address_details.address_components.forEach(function (address)
                        {

                            if (address.types.includes('street_number'))
                            {
                                street_number = address.long_name;
                            } else if (address.types.includes('route'))
                            {
                                street_name = address.long_name;
                            } else if (address.types.includes('locality'))
                            {
                                city = address.long_name;
                            } else if (address.types.includes('administrative_area_level_1'))
                            {
                                state = address.short_name;
                            } else if (address.types.includes('postal_code'))
                            {
                                zip = address.long_name;
                            }

                        });

                        form.querySelector('[name="street_address' + id + '"]').value = street_number + ' ' + street_name;
                        form.querySelector('[name="city' + id + '"]').value = city;
                        form.querySelector('[name="zip_code' + id + '"]').value = zip;
                        form.querySelector('[name="state' + id + '"]').value = state;

                    });

                });
            },

            save_add_address(ele)
            {
                let scope = this;
                let form = document.getElementById('add_address_form');
                let form_data = new FormData(form);

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');

                remove_form_errors();

                axios.post('/agents/pre_listings/save_add_address', form_data)
                    .then(function (response)
                    {
                        scope.show_add_address = false;
                        scope.edit_pre_listing(response.data.listing_id);
                        notify('success', 'Pre-listing saved');
                        ele.innerHTML = button_html;
                    })
                    .catch(function (error)
                    {
                        ele.innerHTML = button_html;
                        display_errors(error, ele, button_html);
                    });

            },

            edit_pre_listing(listing_id)
            {
                let scope = this;

                axios.get('/agents/pre_listings/edit_pre_listing/' + listing_id)
                    .then(function (response)
                    {
                        scope.show_edit_pre_listing = true;
                        scope.clear_edit_pre_listing_form();
                        let form = document.getElementById('edit_pre_listing_form');
                        let photos_form = document.getElementById('photos_form');

                        form.querySelector('[name="listing_id"]').value = listing_id;
                        photos_form.querySelector('[name="listing_id"]').value = listing_id;

                        let listing = response.data.listing;

                        for (var key in listing)
                        {
                            let input = form.querySelector('[name="' + key + '"]');
                            if (input)
                            {
                                input.value = listing[key];
                            }
                        }

                        let address = listing.street_address + ', ' + listing.city + ', ' + listing.state + ' ' + listing.zip_code;
                        scope.$refs.edit_pre_listing_title.innerHTML = address;

                        global_format_money();

                        scope.get_photos(listing_id);

                        scope.required_fields();


                    })
                    .catch(function (error)
                    {

                    });
            },

            save_edit_pre_listing(ele)
            {
                let scope = this;
                let form = document.getElementById('edit_pre_listing_form');
                let form_data = new FormData(form);
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');

                axios.post('/agents/pre_listings/save_edit_pre_listing', form_data)
                    .then(function (response)
                    {
                        notify('success', 'Pre-listing saved');
                        ele.innerHTML = button_html;
                        scope.show_edit_pre_listing = false;
                        scope.get_pre_listings();
                    })
                    .catch(function (error)
                    {
                        ele.innerHTML = button_html;
                        display_errors(error, ele, button_html);
                    });
            },

            required_fields()
            {
                let scope = this;

                let sale_rental = scope.$refs.sale_rental;
                let sale_type = scope.$refs.sale_type;
                let property_type = scope.$refs.property_type;
                let beds = scope.$refs.beds;
                let baths = scope.$refs.baths;
                let year_built = scope.$refs.year_built;

                if (sale_rental.value == 'Sale')
                {
                    sale_type.classList.add('required');
                    sale_type.disabled = false;
                } else
                {
                    sale_type.classList.remove('required');
                    year_built.classList.remove('required');
                    sale_type.disabled = true;
                    sale_type.selectedIndex = 0;
                }

                if (property_type.value == 'Residential')
                {
                    beds.classList.add('required');
                    baths.classList.add('required');
                    year_built.classList.add('required');
                } else
                {
                    beds.classList.remove('required');
                    baths.classList.remove('required');
                    year_built.classList.remove('required');
                }

            },

            add_photos()
            {
                let scope = this;
                let file_input = this.$refs.photos;

                file_input.onchange = () =>
                {
                    let form = document.getElementById('photos_form');
                    let formData = new FormData(form);
                    axios.post('/agents/pre_listings/add_photos', formData)
                        .then(function (response)
                        {
                            scope.get_photos(response.data.listing_id);
                            scope.get_pre_listings();
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }
            },

            save_title(photo_id, title)
            {
                let scope = this;

                let form_data = new FormData();
                form_data.append('photo_id', photo_id);
                form_data.append('title', title);
                axios.post('/agents/pre_listings/save_title', form_data)
                    .then(function (response)
                    {
                        let listing_id = document.getElementById('photos_form').querySelector('[name="listing_id"]').value;
                        scope.get_photos(listing_id);
                        notify('success', 'Title saved');
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            get_photos(listing_id)
            {
                let scope = this;
                axios.get('/agents/pre_listings/get_photos/' + listing_id)
                    .then(function (response)
                    {
                        scope.$refs.photos_div.innerHTML = response.data;
                        setTimeout(function ()
                        {
                            scope.sortable();
                        }, 500);
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            sortable()
            {
                let scope = this;
                let sortable_div = scope.$refs.sortable_div;

                let sortable = Sortable.create(sortable_div, {
                    handle: ".handle",  // Drag handle selector within list items
                    draggable: ".photo-div",  // Specifies which items inside the element should be draggable
                    chosenClass: "sortable-photos",  // Class name for the chosen item
                    ghostClass: "sortable-ghost",  // Class name for the drop placeholder
                    dragClass: "sortable-drag",  // Class name for the dragging item

                    onEnd: function (evt)
                    {
                        scope.update_photos_order(sortable_div);
                    },

                });
            },

            update_photos_order(sortable_div)
            {

                let photos = [];
                sortable_div.querySelectorAll('.photo-div').forEach(function (photo, i)
                {
                    let data = {
                        id: photo.getAttribute('data-id'),
                        order: i
                    }
                    photos.push(data);
                });

                let formData = new FormData();
                formData.append('photos', JSON.stringify(photos));
                axios.post('/agents/pre_listings/update_photos_order', formData)
                    .then(function (response)
                    {
                        notify('success', 'Reorder Successful');
                    })
                    .catch(function (error)
                    {
                    });

            },


            delete_photo(photo_id, listing_id)
            {
                if (confirm('Are you sure you want to delete this photo?'))
                {
                    let scope = this;
                    axios.post('/agents/pre_listings/delete_photo', { photo_id: photo_id })
                        .then(function (response)
                        {
                            scope.get_photos(listing_id);
                            notify('success', 'Photo deleted');
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }
            },

            sale_rental_changed()
            {
                let scope = this;
                let sale_rental = scope.$refs.sale_rental;
                let sale_type = scope.$refs.sale_type;

                if (sale_rental.value == 'Rental')
                {
                    sale_type.disabled = true;
                    sale_type.selectedIndex = 0;
                } else
                {
                    sale_type.disabled = false;
                }
            },

            clear_address_form()
            {
                let form = document.getElementById('add_address_form');
                form.reset();
                remove_form_errors();
            },

            clear_edit_pre_listing_form()
            {
                let form = document.getElementById('edit_pre_listing_form');
                form.reset();
                remove_form_errors();
            },
        }
    }
}